import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AuthConfig, AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './navigation/header/header.component';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { GalleriaModule } from 'primeng/galleria';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ColorPickerModule } from 'primeng/colorpicker';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';

import { ConfirmationService, MessageService } from 'primeng/api';

import { HighlightModule, HIGHLIGHT_OPTIONS, HighlightOptions } from 'ngx-highlightjs';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AngularSplitModule } from 'angular-split';

import { MainComponent } from './main/components/main/main.component';
import { HomeComponent } from './main/components/home/home.component';
import { FileTreeComponent } from './main/components/file-tree/file-tree.component';
import { CodeComponent } from './main/components/code/code.component';
import { DiagramComponent } from './main/components/diagram/diagram.component';
import { DynamicTableComponent } from './main/components/dynamic-table/dynamic-table.component';
import { FileViewerComponent } from './main/components/file-viewer/file-viewer.component';
import { ResultsSummaryComponent } from './main/components/results-summary/results-summary.component';
import { ProcessComponent } from './main/components/process/process.component';
import { SegmentationComponent } from './main/components/process/segmentation/segmentation.component';
import { HtquantComponent } from './main/components/process/htquant/htquant.component';
import { SiftComponent } from './main/components/process/sift/sift.component';
import { KidneyClassifierComponent } from './main/components/process/kidney-classifier/kidney-classifier.component';
import { ProcessControlComponent } from './main/components/process/process-control/process-control.component';
import { EDOFComponent } from './main/components/process/edof/edof.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { DetailsComponent } from './main/components/details/details.component';
import { SelectionDialogComponent } from './main/components/diagram/selection-dialog.component';

import { InstanceofPipe } from './main/pipes/instanceof.pipe';
import { FormatFileSizePipe } from './main/pipes/format-file-size.pipe';
import { ProcessStatusComponent } from './main/components/process/process-status/process-status/process-status.component';
import { ProcessStatusItemComponent } from './main/components/process/process-status/process-status-item/process-status-item.component';
import { getSaver, SAVER } from './download/saver.provider';
import { DeconvolutionComponent } from './main/components/process/deconvolution/deconvolution.component';
import { YolosegdetectComponent } from './main/components/process/yolosegdetect/yolosegdetect.component';
import { RetinalLayerComponent } from './main/components/process/retinal-layer/retinal-layer.component';

const config: AuthConfig = {
  ...environment.auth,
  httpInterceptor: {
    allowedList: [
      // any/all calls to endpoints in CIMG API
      ...Object.values(environment.securedURLs).map((url) => {
        return {
          uri: `${url}/*`,
          allowAnonymous: true, // we still want anonymous/non-authenticated users to search
        };
      }),
    ],
  },
};

@NgModule({
  declarations: [
    AppComponent,
    CodeComponent,
    HeaderComponent,
    MainComponent,
    HomeComponent,
    FileTreeComponent,
    DiagramComponent,
    DynamicTableComponent,
    ResultsSummaryComponent,
    FileViewerComponent,
    ProcessComponent,
    SegmentationComponent,
    HtquantComponent,
    SiftComponent,
    ProcessControlComponent,
    EDOFComponent,
    KidneyClassifierComponent,
    DeconvolutionComponent,
    YolosegdetectComponent,
    RetinalLayerComponent,
    FooterComponent,
    DetailsComponent,
    InstanceofPipe,
    FormatFileSizePipe,
    ProcessStatusComponent,
    ProcessStatusItemComponent,
    SelectionDialogComponent
  ],
  imports: [
    AuthModule.forRoot(config),
    RouterModule.forRoot([]),
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule,
    CardModule,
    CheckboxModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ContextMenuModule,
    DataViewModule,
    DividerModule,
    DropdownModule,
    DynamicDialogModule,
    FormsModule,
    GalleriaModule,
    HighlightModule,
    HttpClientModule,
    InputTextModule,
    InputNumberModule,
    PanelModule,
    PdfViewerModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RippleModule,
    RouterModule,
    SelectButtonModule,
    TabMenuModule,
    TableModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    AngularSplitModule,
    ColorPickerModule,
    InputSwitchModule,
    DialogModule,
    FileUploadModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: <HighlightOptions>{
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          json: () => import('highlight.js/lib/languages/json'),
        },
      },
    },
    ConfirmationService,
    MessageService,
    DialogService,
    { provide: SAVER, useFactory: getSaver }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
