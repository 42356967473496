import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { MainFacade } from '../../../main.facade';
import { ProcessFacade } from '../process.facade';
import { FileFolder, IFile, IFileInfo, ImageFile, RawFileInfo } from '../../../models/file-info';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestFactory } from '../../../models/request.factory';

@Component({
  selector: 'yolosegdetect',
  templateUrl: './yolosegdetect.component.html',
  styleUrls: ['./yolosegdetect.component.scss'],
})
export class YolosegdetectComponent implements OnInit {

  form!: FormGroup;
  public bucket: string | undefined = undefined;
  public path: string | undefined = undefined;
  validationMessage: string | null = null;

  public inputImages: IFileInfo[] = [];
  // public imageDirectories: IFileInfo[] = [];

  public models: any[] = [
    { name: 'Optic Nerve', value: 'jax-ml-dl-models/YOLOv8/Optic-nerve-YOLOv8-models/yolov8x_seg_opticnerve_finetuned_V1_torch_2_3_1cu11_8_ultralytics_8_2_77.pt' },
    { name: 'Retina', value: 'jax-ml-dl-models/YOLOv8/Retina-YOLOv8-models/retina-seg-detect-yolov8-python-3-10-ultralytics-8-2-77-torch-2-3-1.pt' }
  ];

  constructor(private mainFacade: MainFacade,
              private processFacade: ProcessFacade,
              private fb: FormBuilder) {}

  ngOnInit(): void {
    this.configForm();
    this.mainFacade.getSelectedNode$().subscribe({
      next: (node) => {
        if (node == undefined || node.data == undefined) {
          this.bucket = undefined;
        } else {
          this.setImagesInput(node);
        }
      },
      error: (err) => {
        console.error(`Error with node selection: ${err}`);
      },
    });
    this.mainFacade.getValidationMessage$().subscribe({
      next: (message) => {
        this.validationMessage = message;
      }
    });
  }

  setImagesInput(node: TreeNode<IFileInfo>) {
    this.inputImages = [];
    // if folder
    if (!node.leaf) {
      node.children?.forEach(child => {
        if (child.data && this.isImageExtension(child.data.name)) {
          this.inputImages.push(child.data);
        }
      });
    } else {
      // if file
      if (node.data && this.isImageExtension(node.data.name)) {
        this.inputImages.push(node.data);
      }
    }
    this.bucket = node.data?.project.bucket;
    this.path = node.parent?.data?.relPath;
  }

  isImageExtension(name: string) {
    const extension = name.toLowerCase().split('.').pop();
    return extension === 'tif' || extension === 'tiff' || extension === 'png' || extension === 'jpg' || extension === 'jpeg' || extension === 'ndpi';
  }
  configForm() {
    this.form = this.fb.group({
      modelFile: [this.models[0], Validators.required],
      downSamplingFactor: [5, Validators.required],
      overlapX: [60, Validators.required],
      overlapY: [60, Validators.required],
      confidence: [0.6, Validators.required],
      iouThreshold: [0.5, Validators.required],
      nmsThreshold: [0.3, Validators.required],
      saveSegment: [false],
      outputFolder: [null, Validators.required],
      editOutputFolder: [false]
    });
  }

  onSubmit() {
    const formValue = this.form.value;
    const bucket = this.bucket;
    const parentPath = this.path;
    const yolosegdetectRequest = RequestFactory.getYolosegdetectRequest(bucket, parentPath,
      this.inputImages, formValue.modelFile.value, formValue.outputFolder);
    this.mainFacade.runProcess(yolosegdetectRequest);
  }

}
