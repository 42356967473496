<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <p class="my-2">
    Please select an image file in the file browser.
  </p>
  <p class="my-2">File selected is : {{this.inputImage?.name}}</p>
  <br>
  <p-divider align="left">
    <div class="inline-flex align-items-center font-bold">
      Stain 1 / RGB absorption
    </div>
  </p-divider>

  <div class="field grid">
    <label for="stain1" class="col-12 mb-2 md:col-3 md:mb-0 text-black-alpha-90">
      Stain 1
      <i class="pi pi-info-circle px-1"
         pTooltip="Select stain 1"
         tooltipPosition="bottom"
      ></i>
    </label>
    <div id="stain1" class="col-12 md:col-9 mb-2">
      <p-dropdown
        inputId="stain1"
        placeholder="SELECT STAIN 1"
        formControlName="stain1"
        [options]="stainAbsorptionReferences"
        (onChange)="onStain1Select($event.value)"
        optionLabel="name"
      ></p-dropdown>
    </div>

    <div class="col-12 mt-2">
      <div class="field grid">
        <label for="red1" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
          Red absorption:
        </label>

        <div id="red1" class="col-12 md:col-5">
          <p-inputNumber
            inputId="red1"
            name="red1"
            formControlName="red1"
            [minFractionDigits]="0"
            [maxFractionDigits]="3"
            [showButtons]="true"
            [min]="0"
            [max]="1"
            class="p-inputtext-sm"
          ></p-inputNumber>
        </div>
      </div>
      <div class="field grid">
        <label for="green1" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
          Green absorption:
        </label>
        <div id="green1" class="col-12 md:col-5">
          <p-inputNumber
            inputId="green1"
            name="green1"
            formControlName="green1"
            [minFractionDigits]="0"
            [maxFractionDigits]="3"
            [showButtons]="true"
            [min]="0"
            [max]="1"
            class="p-inputtext-sm"
          ></p-inputNumber>
        </div>
      </div>
      <div class="field grid">
        <label for="blue1" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
          Blue absorption:
        </label>
        <div id="blue1" class="col-12 md:col-5">
          <p-inputNumber
            inputId="blue1"
            name="blue1"
            formControlName="blue1"
            [minFractionDigits]="0"
            [maxFractionDigits]="3"
            [showButtons]="true"
            [min]="0"
            [max]="1"
            class="p-inputtext-sm"
          ></p-inputNumber>
        </div>
      </div>
    </div>
  </div>

  <div class="field grid">
    <label for="stain1max" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
      Stain 1 Maximum:
    </label>
    <div id="stain1max" class="col-12 md:col-5">
      <p-inputNumber
        inputId="stain1max"
        name="stain1max"
        formControlName="stain1max"
        [minFractionDigits]="0"
        [maxFractionDigits]="1"
        [showButtons]="true"
        [min]="0"
        class="p-inputtext-sm"
      ></p-inputNumber>
    </div>
  </div>

  <p-divider align="left">
    <div class="inline-flex align-items-center font-bold">
      Stain 2 / RGB absorption
    </div>
  </p-divider>

  <div class="field grid">
    <label for="stain2" class="col-12 mb-2 md:col-3 md:mb-0 text-black-alpha-90">
      Stain 2
      <i class="pi pi-info-circle px-1"
         pTooltip="Select stain 2"
         tooltipPosition="bottom"
      ></i>
    </label>
    <div id="stain2" class="col-12 md:col-9 mb-2">
      <p-dropdown
        placeholder="SELECT STAIN 2"
        formControlName="stain2"
        [options]="stainAbsorptionReferences"
        (onChange)="onStain2Select($event.value)"
        optionLabel="name"
      ></p-dropdown>
    </div>


    <div class="col-12 mt-2">
      <div class="field grid">
        <label for="red2" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
          Red absorption:
        </label>

        <div id="red2" class="col-12 md:col-5">
          <p-inputNumber
            inputId="red2"
            name="red2"
            formControlName="red2"
            [minFractionDigits]="0"
            [maxFractionDigits]="3"
            [showButtons]="true"
            [min]="0"
            [max]="1"
            class="p-inputtext-sm"
          ></p-inputNumber>
        </div>
      </div>
      <div class="field grid">
        <label for="green2" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
          Green absorption:
        </label>
        <div id="green2" class="col-12 md:col-5">
          <p-inputNumber
            inputId="green2"
            name="green2"
            formControlName="green2"
            [minFractionDigits]="0"
            [maxFractionDigits]="3"
            [showButtons]="true"
            [min]="0"
            [max]="1"
            class="p-inputtext-sm"
          ></p-inputNumber>
        </div>
      </div>
      <div class="field grid">
        <label for="blue2" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
          Blue absorption:
        </label>
        <div id="blue2" class="col-12 md:col-5">
          <p-inputNumber
            inputId="blue2"
            name="blue2"
            formControlName="blue2"
            [minFractionDigits]="0"
            [maxFractionDigits]="3"
            [showButtons]="true"
            [min]="0"
            [max]="1"
            class="p-inputtext-sm"
          ></p-inputNumber>
        </div>
      </div>
    </div>
  </div>

  <div class="field grid">
    <label for="stain2max" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
      Stain 2 Maximum:
    </label>
    <div id="stain2max" class="col-12 md:col-5">
      <p-inputNumber
        inputId="stain2max"
        name="stain2max"
        formControlName="stain2max"
        [minFractionDigits]="0"
        [maxFractionDigits]="1"
        [showButtons]="true"
        [min]="0"
        class="p-inputtext-sm"
      ></p-inputNumber>
    </div>
  </div>

  <p-divider align="left">
    <div class="inline-flex align-items-center font-bold">
      Miscellaneous
    </div>
  </p-divider>

  <div class="field grid">
    <label for="alpha" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
      Alpha:
    </label>
    <div id="alpha" class="col-12 md:col-5">
      <p-inputNumber
        inputId="alpha"
        name="alpha"
        formControlName="alpha"
        [minFractionDigits]="0"
        [maxFractionDigits]="1"
        [showButtons]="true"
        [min]="0"
        class="p-inputtext-sm"
      ></p-inputNumber>
    </div>
  </div>

  <div class="field grid">
    <label for="beta" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
      Beta:
    </label>
    <div id="beta" class="col-12 md:col-5">
      <p-inputNumber
        inputId="beta"
        name="beta"
        formControlName="beta"
        [minFractionDigits]="0"
        [maxFractionDigits]="2"
        [showButtons]="true"
        [min]="0"
        class="p-inputtext-sm"
      ></p-inputNumber>
    </div>
  </div>

  <div class="field grid">
    <label for="intensityNorm" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
      Intensity Normalization:
    </label>
    <div id="intensityNorm" class="col-12 md:col-5">
      <p-inputNumber
        inputId="intensityNorm"
        name="intensityNorm"
        formControlName="intensityNorm"
        [showButtons]="true"
        [min]="0"
        class="p-inputtext-sm"
      ></p-inputNumber>
    </div>
  </div>

  <div class="field grid">
    <label for="grayscale" class="col-12 md:col-7 md:mb-0 text-black-alpha-90">
      Grayscale:
    </label>
    <div id="grayscale" class="col-12 md:col-5">
      <p-inputSwitch formControlName="grayscale"></p-inputSwitch>
    </div>
  </div>

  <!-- this section becomes visible when file gets selected -->
  <ng-container>
    <p-divider align="left">
      <div class="inline-flex align-items-center font-bold">
        File Destination
      </div>
    </p-divider>

    <!-- translated output -->
    <div class="field grid h-3rem align-items-center">
      <label for="outputPath" class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Output Path:
      </label>
      <div class="col-12 md:col-7">
        <span *ngIf="!isOutputPathEditable">
          {{form.controls['outputPath'].value}}
          <i class="pi pi-pencil icon-hover mx-2" (click)="onEditOutputPath(true)"></i>
        </span>
        <span *ngIf="isOutputPathEditable" class="p-input-icon-right">
          <i class="pi pi-times icon-hover" (click)="onEditOutputPath(false)"></i>
          <input id="outputPath" class="p-inputtext-sm w-full" type="text"
                 formControlName="outputPath" pInputText/>
        </span>
      </div>
    </div>

    <div class="pt-3 pb-3">
      <span *ngIf="validationMessage" style="color: red" class="mb-2">{{ validationMessage }}</span>
    </div>
    <div class="pt-3 pb-3 process-buttons">
      <p-button
        type="submit" icon="pi pi-play"
        label="Run"
        styleClass="p-button-primary">
      </p-button>
    </div>


  </ng-container>
</form>
