import { Component, OnInit, ViewChild } from '@angular/core';

import { ProcessFacade } from './process.facade';
import { MainFacade } from '../../main.facade';
import { Dropdown } from 'primeng/dropdown';

// currently, only used in this component, but ok to
// export in case it becomes needed elsewhere
interface IProcessType {
  name: string,
  desc: string
}

@Component({
  selector: 'process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss'],
})
export class ProcessComponent implements OnInit {

  readonly PROCESS_TYPES = [
    {
      name: 'Slide Cropper',
      desc: 'extract regions of interest, manually or automatically'
    },
    {
      name: 'Fibers and Nuclei',
      desc: 'quantify Fibers and Nuclei in regions of interest'
    },
    {
      name: 'Image Alignment',
      desc: 'align bright field and second harmonic generation images and output an overlayed .tiff'
    },
    {
      name: 'Extended Depth of Field',
      desc: 'generate a single image from a stack'
    },
    {
      name: 'Kidney Classifier',
      desc: 'analyzes kidney histology, generating heatmaps for damage assessment, and provides detailed CSV reports at pixel, slide, and section levels, along with tissue masks'
    },
    {
      name: 'Color Deconvolution',
      desc: 'decomposes images of slides stained with a pair of stains into the contributions from each stain. By default, the program assumes that the input image is an H&E (hematoxylin and eosin) image.'
    },
    {
      name: 'Retinal Layer Segmentation',
      desc: 'segments the retina areas in images of eyeball sections using the Resunet or Vnet models'
    },
    {
      name: 'Yolo Segmentation',
      desc: 'detects/segments objects in images using the YOLOv8x model fine tuned on the optic nerve area and the retina area of the mouse eyeball section.'
    }
  ] as IProcessType[];

  public selectedType: any = null;
  public selectedTypeObj: any = null;
  public processes = [
    this.PROCESS_TYPES[0],
    this.PROCESS_TYPES[1],
    this.PROCESS_TYPES[2],
    this.PROCESS_TYPES[3],
    this.PROCESS_TYPES[4],
    this.PROCESS_TYPES[5],
    this.PROCESS_TYPES[6],
    this.PROCESS_TYPES[7]
  ];

  disableProcess = true;

  @ViewChild(Dropdown) processTypeDropdown!: Dropdown;

  constructor(public mainFacade: MainFacade,
              public processFacade: ProcessFacade) {
  }

  ngOnInit(): void {
    this.selectedTypeObj = this.processFacade.getSelectedType();
    this.selectedType = this.selectedTypeObj?.name;
    this.processFacade.trackLoadedFiles(this.mainFacade);
    this.mainFacade.isFileSelected().subscribe((exists) => {
      this.disableProcess = !exists;
    });
  }

  onProcessSelect(event: any) {
    this.selectedType = event.value.name;
    this.processFacade.setSelectedType(event.value);
  }

  getProcessTypeFromName(name: string): unknown {
    return this.PROCESS_TYPES.find(element => element.name === name);
  }

}
