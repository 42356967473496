import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { MainFacade } from '../../main.facade';

import { Subject, Subscription } from 'rxjs';

import { PlotFacade } from './plot.facade';
import { SelectionDialogComponent } from './selection-dialog.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { ImageInfo } from '../../models/file-info';
import { Polygon } from '../../models/plotting/region';

@Component({
  selector: 'diagram',
  templateUrl: './diagram.component.html',
  styleUrls: ['./diagram.component.scss'],
})
export class DiagramComponent implements OnInit, OnDestroy {

  @Output()
  isStackEvent = new EventEmitter(false);
  @Output()
  isGrayscaleEvent = new EventEmitter(false);
  loadingMessage = 'Loading image...';
  zoom = false;
  fileName: string | undefined;
  imageInfo: ImageInfo | undefined;

  private unsub = new Subject<void>();
  private previewSubscription = new Subscription();

  private width = 0;
  private height = 0;
  public stackLoading = false;
  public imgLoading = false;
  public loadingPercentage = 0;
  private running = false;
  public zIndex = 0;
  public maxIndex = 0;

  displayHelpDialog = false;

  colormapsOptions!: any;
  reversescale = false;
  scaleratio = true;
  selectedColormap!: any;
  stackOptions!: any[];
  selectedStackOption!: any;
  plotDivName = 'plot';

  ref!: DynamicDialogRef;

  plotWidthSubscription?: Subscription;
  imageLoadingSubscription?: Subscription;
  imgLoadingMessageSubscription?: Subscription;
  isZoomSubscription?: Subscription;
  filenameSubscription?: Subscription;
  autoscaleSubscription?: Subscription;

  constructor(public mainFacade: MainFacade, public plotFacade: PlotFacade,
              public dialogService: DialogService, public messageService: MessageService) {
    this.colormapsOptions = plotFacade.getColormapOptions();
  }

  ngOnInit(): void {
    this.mainFacade.setDiagram(this);
    this.selectedColormap = this.plotFacade.getColormapOptions()[0];
    this.stackOptions = [{ name: 'Single image', val: 'false' }, { name: 'Stack', val: 'true' }];
    this.selectedStackOption = this.stackOptions[0];
    this.autoscaleSubscription = this.plotFacade.getAutoscaleEvent().subscribe(() => {
      // reset the image mode to single image
      this.selectedStackOption = { name: 'Single image', val: 'false' };
    });
    this.imageLoadingSubscription = this.mainFacade.isImageLoading$().subscribe(isImageLoading => {
      this.imgLoading = isImageLoading;
    });
    this.plotFacade.getStackLoadingProgress().subscribe(loadingProgress => {
      this.loadingPercentage = loadingProgress;
    });
    this.plotFacade.isStackLoading().subscribe(stackLoading => {
      this.stackLoading = stackLoading;
    });
    this.plotWidthSubscription = this.mainFacade.getPanelWidth().subscribe(() => {
      this.plotFacade.relayout();
    });
    this.imgLoadingMessageSubscription = this.mainFacade.getImageLoadingMessage().subscribe(message => {
      this.loadingMessage = message;
    });
    this.isZoomSubscription = this.mainFacade.isZoom().subscribe(zoom => {
      this.zoom = zoom;
    });
    this.filenameSubscription = this.mainFacade.getFilename().subscribe(filename => {
      if (filename) {
        this.fileName = filename;
      }
    });
    this.plotFacade.getColormap().subscribe(colormap => {
      this.selectedColormap = colormap;
    });
    this.plotFacade.getReverseScale().subscribe(reversescale => {
      this.reversescale = reversescale;
    });
    this.plotFacade.getScaleRatio().subscribe(scaleratio => {
      this.scaleratio = scaleratio;
    });
    this.previewSubscription = this.mainFacade.getImageInfo$().subscribe({ next: imgInfo => {
      if (imgInfo) {
        this.imageInfo = imgInfo;
        // reset stack options selection
        this.selectedStackOption = this.stackOptions ? this.stackOptions[0] : undefined;
        this.isGrayscaleEvent.emit(this.imageInfo.isGrayscale);
        this.isStackEvent.emit(this.imageInfo.isStack);
        const urls = imgInfo.urls;
        if (!this.running) {
          // image size
          const appDiv: HTMLElement | null = document.getElementById('diagram');
          let screenHeight = 500;
          if (appDiv && appDiv.parentNode && appDiv.parentNode.parentElement) {
            screenHeight = appDiv.parentNode.parentElement.offsetHeight;
          }
          if (urls) {
            this.plotFacade.reset();
            if (imgInfo.isStack && imgInfo.showStack) {
              this.stackLoading = true;
            }
            // make sure the zindex is within bounds
            this.updateZIndex(urls);
            this.running = true;
            // set max index of stack
            this.maxIndex = urls.length > 2 ? urls.length - 2 : 0;
            this.plotFacade.load(imgInfo, this.zIndex).then(loadedImage=> {
              // retrieve width, height and ratios for regions functions
              this.width = loadedImage.sizes[0];
              this.height = loadedImage.sizes[1];
              // check if the latest file selected is what has been loaded and not a previously loaded image
              if (imgInfo.fileName === loadedImage.filename) {
                // plot
                this.plotFacade.plot(this.plotDivName, loadedImage, imgInfo, screenHeight).then(() => {
                  if (imgInfo.isStack && imgInfo.showStack) {
                    this.stackLoading = false;
                  }
                  this.mainFacade.setImageLoading$(false);
                  this.running = false;
                  // if roi present then we import it and add them to the plot
                  if (imgInfo.roiJsonStr) {
                    const regions = this.plotFacade.importRegions(imgInfo.roiJsonStr);
                    this.plotFacade.setRegions(regions);
                  }
                  console.log('finished plotting');
                });
              }
            });
          }
        }
      }}, error: err => {
        console.log('Error occured when getting image info:' + err);
        this.messageService.add({ sticky: true, severity:'error', summary:'An error occured',
           detail:`The following error occured while getting image info: ${err}.
                   Please try to open the image again through the file navigator.` });
        this.stackLoading = false;
        this.mainFacade.setImageLoading$(false);
        this.running = false;
        this.plotFacade.reset();
      }
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
    if (this.previewSubscription) {
      this.previewSubscription.unsubscribe();
    }
    if (this.plotWidthSubscription) {
      this.plotWidthSubscription.unsubscribe();
    }
    if (this.ref) {
      this.ref.close();
    }
    if (this.imageLoadingSubscription) {
      this.imageLoadingSubscription.unsubscribe();
    }
    if (this.imgLoadingMessageSubscription) {
      this.imgLoadingMessageSubscription.unsubscribe();
    }
    if (this.isZoomSubscription) {
      this.isZoomSubscription.unsubscribe();
    }
    if (this.autoscaleSubscription) {
      this.autoscaleSubscription.unsubscribe();
    }
    if (this.filenameSubscription) {
      this.filenameSubscription.unsubscribe();
    }
    this.plotFacade.unsubscribe();
  }

  public hasRegions(): boolean {
    const figs: any[] = this.getSelectionFigures();
    return figs != null && figs.length > 0;
  }

  /**
   * This method is called in mainFacade.configRequest() - DO NOT DELETE
   */
  public getRegionPolygons(): Polygon[] {
    return this.plotFacade.getRegionPolygons();
  }
  private getSelectionFigures(): any[] {
    const ret: any[] = [];
    const figures = this.plotFacade.getShapes();
    figures.forEach((fig: any) => {
      if (fig == null) return;
      ret.push(fig);
    });
    return ret;
  }

  /**
   * Select the color scale type: called by the color scale select button
   * @param reversescale
   */
  selectReverseScale(reversescale: boolean) {
    this.plotFacade.setReverseScale(reversescale);
  }

  /**
   * Select the scale ratio
   * @param scaleratio
   */
  selectScaleRatio(scaleratio: boolean) {
    this.plotFacade.setScaleRatio(scaleratio);
  }

  /**
   * Select a color map: called by the color map dropdown button
   * @param colormap
   */
  selectColormap(colormap: any) {
    this.plotFacade.setColormap(colormap);
  }

  /**
   * Called by the stack/single image dropdown button
   * @param showstack
   */
  selectStackOption(selectedStackOption: any) {
    const showstack = selectedStackOption.val === 'true';
    this.stackLoading = showstack;
    this.mainFacade.setImageLoading$(!showstack);
    this.plotFacade.setShowStack(showstack);
  }

  onSelectionEditClick() {
    this.ref = this.dialogService.open(SelectionDialogComponent, {
      header: 'Add/Edit region selection(s)',
      width: '19.5%',
      height: '89%',
      contentStyle: { 'overflow': 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      resizable: true,
      draggable: true,
      modal:	false, //	Defines if background should be blocked when dialog is displayed.
      position: 'right',
      styleClass: 'custom-dialog-header', // Add background color to the header
      data: { shapes: this.plotFacade.getShapes() }
    });
    this.ref.onClose.subscribe((result) => {
      if (result) {
        const shapes = result.shapes;
        const showLabel = result.showLabel;
        const shapeColor = result.shapeColor;
        const fillColor = result.fillColor;
        const labelColor = result.labelColor;
        // create new regions
        this.plotFacade.setRegions(shapes, showLabel, true, shapeColor, fillColor, labelColor);
      } else {
        // set previous shapes
        this.plotFacade.setPreviousShapes();
      }
    });
  }

  updateZIndex(urls?: string[]) {
    if (this.zIndex > this.maxIndex) {
      this.zIndex = this.maxIndex;
    }
    if (this.zIndex < 0) {
      this.zIndex = 0;
    }
    if (urls) {
      if (this.zIndex > urls?.length) {
        this.zIndex = 0;
        this.maxIndex = urls.length - 2;
      }
    }
    this.plotFacade.setZIndex(this.zIndex);
  }
  reloadAndPlot() {
    this.mainFacade.setImageLoading$(true);
    this.plotFacade.reloadAndPlot();
  }
  cancelLoading() {
    this.stackLoading = false;
    this.imgLoading = false;
    this.mainFacade.setImageLoading$(false);
    this.zIndex = 0;
    this.plotFacade.setZIndex(this.zIndex);
    this.running = false;
    this.plotFacade.setStackLoading(false);
  }

  showHelp() {
    this.displayHelpDialog = true;
  }
}

