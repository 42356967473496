<!--<p-toast></p-toast>-->
<div class="grid">

  <div class="col-12">
    <div class="flex flex-wrap">
      <div class="flex-initial flex align-items-center justify-content-center font-bold m-2">
        <p-checkbox [(ngModel)]="showShapeLabel" [binary]="true" label="Display label"
                    pTooltip="Show label on each shape selected" tooltipPosition="bottom"
                    (onChange)="changeShowShapeLabel($event.checked)"></p-checkbox>
        <i class="pi pi-info-circle px-1 mr-2"
           pTooltip="Labels are not shown by default. If shown, it will slow down the ROI handling, ie moving
                    the regions with the mouse over the image will be slower than without the labels shown."
           tooltipPosition="bottom"
        ></i>
      </div>

      <div class="flex-initial flex align-items-center justify-content-center font-bold m-2">
        <div class="flex">
          <div class="flex-initial flex align-items-center justify-content-end font-bold m-2">
            <label>Shape Color:</label>
          </div>
          <div class="flex-initial flex align-items-center justify-content-center font-bold m-2">
            <p-colorPicker [(ngModel)]="shapeColor" class="flex-initial flex align-items-center
                           justify-content-center border-round mr-2"
                           (onChange)="changeShapeColor($event.value)"
                           pTooltip="Change shape color" tooltipPosition="bottom">
            </p-colorPicker>
          </div>
        </div>
        <div class="flex">
          <div class="flex-initial flex align-items-center justify-content-end font-bold m-2">
            <label>Shape Fill Color:</label>
          </div>
          <div class="flex-initial flex align-items-center justify-content-center font-bold m-2">
            <p-colorPicker [(ngModel)]="fillColor" class="flex-initial
                  flex align-items-center justify-content-center border-round mr-2"
                           (onChange)="changeFillColor($event.value)"
                           pTooltip="Change shape fill color" tooltipPosition="bottom">
            </p-colorPicker>
          </div>
        </div>
        <div class="flex">
          <div class="flex-initial flex align-items-center justify-content-end font-bold m-2">
            <label>Label Color:</label>
          </div>
          <div class="flex-initial flex align-items-center justify-content-center font-bold m-2">
            <p-colorPicker [(ngModel)]="labelColor" class="flex-initial
                  flex align-items-center justify-content-center border-round mr-2"
                           (onChange)="changeLabelColor($event.value)"
                           pTooltip="Change label color" tooltipPosition="bottom">
            </p-colorPicker>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <p-table #dt [value]="regions" [tableStyle]="{'min-width': '50rem'}" [(selection)]="selectedRegions"
             [rowHover]="true" dataKey="name" styleClass="p-datatable-sm">
      <ng-template pTemplate="caption">
        <div class="flex">
          <p-button  styleClass="mr-2" pTooltip="Add new rectangle selection" tooltipPosition="bottom"
                  (click)="addRectangle()">
            <ng-template pTemplate="content">
              <img alt="logo" src="assets/icons/shape-rectangle-plus.svg" style="width: 1.2rem"/>
            </ng-template>
          </p-button>
          <p-button styleClass="mr-2" pTooltip="Add new polygon selection" tooltipPosition="bottom"
                  (click)="addPolygon()">
            <ng-template pTemplate="content">
              <img alt="logo" src="assets/icons/shape-polygon-plus.svg" style="width: 1.2rem"/>
            </ng-template>
          </p-button>
          <p-fileUpload
            styleClass="mr-2"
            mode="basic"
            chooseIcon="pi pi-upload"
            pTooltip="Import ROIS from geojson format"
            tooltipPosition="bottom"
            url="https://www.primefaces.org/cdn/api/upload.php"
            accept=".geojson,.json"
            [maxFileSize]="10000000"
            (onUpload)="importRois($event)"
            [auto]="true"/>
          <p-button styleClass="mr-2" pTooltip="Export/Save ROIs to geojson format"
                    tooltipPosition="bottom" icon="pi pi-download"
                    (click)="exportRois()">
          </p-button>
          <p-button label="x512" styleClass=" mr-2" pTooltip="Round all rectangle regions
                    to width and height that are multiple of 512" tooltipPosition="bottom"
                    (click)="roundRectangleLengths()">
          </p-button>
          <p-button icon="pi pi-trash" styleClass="p-button-danger mr-2"
                  (click)="deleteSelectedRegions()"
                  [disabled]="!selectedRegions || !selectedRegions.length"></p-button>
          <p-button icon="pi pi-question"
                    styleClass="p-button-outlined p-button-rounded"
                  (click)="showHelp()" pTooltip="Help" tooltipPosition="bottom"></p-button>

        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width:3%">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th style="width:3%"></th>
          <th style="width:20%">Name <p-sortIcon field="name"></p-sortIcon></th>
          <th style="width:70%">Coordinates</th>
          <th style="width:4%"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-region let-expanded="expanded" let-shapeIdx="rowIndex">
        <tr>
          <td>
            <p-tableCheckbox [value]="region"></p-tableCheckbox>
          </td>
          <td>
            <button type="button" pButton pRipple [pRowToggler]="region"
                    class="p-button-text p-button-rounded p-button-plain"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td>{{region.name}}</td>
          <td class="text-ellipsis">{{region.toString()}}</td>
          <td>
<!--            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"-->
<!--                    (click)="editSelection(shape)"></button>-->
            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-text p-button-warning"
                    (click)="deleteRegion(shapeIdx)"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-region>
        <tr>
          <!--   if rectangle  -->
          <td *ngIf="isRectangle(region)" colspan="4">
            <div class="p-3">
              <p-table [value]="[region.bounds]" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width:25%">X</th>
                    <th style="width:25%">Y</th>
                    <th style="width:25%">Width</th>
                    <th style="width:25%">Height</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rectinfo>
                  <tr>
                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-inputNumber [(ngModel)]="rectinfo.x"
                                         (onInput)="xRectUpdate(region, $event.value)"
                                         (keydown)="disableArrowKeys($event)">
                          </p-inputNumber>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{rectinfo.x}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-inputNumber [(ngModel)]="rectinfo.y"
                                         (onInput)="yRectUpdate(region, $event.value)"
                                         (keydown)="disableArrowKeys($event)">
                          </p-inputNumber>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{rectinfo.y}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-inputNumber [(ngModel)]="rectinfo.width"
                                         (onInput)="widthRectUpdate(region, $event.value)"
                                         (keydown)="disableArrowKeys($event)">
                          </p-inputNumber>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{rectinfo.width}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-inputNumber [(ngModel)]="rectinfo.height"
                                         (onInput)="heightRectUpdate(region, $event.value)"
                                         (keydown)="disableArrowKeys($event)">
                          </p-inputNumber>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{rectinfo.height}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </td>
          <!--   if polygon       -->
          <td *ngIf="!isRectangle(region)" colspan="4">
            <div class="p-3">
              <p-table [value]="region.bounds.coordinates" dataKey="id" styleClass="p-datatable-sm">
                <ng-template pTemplate="caption">
                  <div class="flex">
                    <button pButton pRipple icon="pi pi-plus" label="Add new point"
                            class="flex-initial flex align-items-center justify-content-center
                            font-bold m-2 border-round" pTooltip="Add new point coordinate"
                            tooltipPosition="bottom" (click)="addCoordinate(region)"></button>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width:40%">X</th>
                    <th style="width:40%">Y</th>
                    <th style="width:20%"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-coordinate let-ri="rowIndex">
                  <tr>
                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-inputNumber [(ngModel)]="coordinate[0]"
                                         (onInput)="xCoordinateUpdate(region, ri, $event.value)"
                                         (keydown)="disableArrowKeys($event)">
                          </p-inputNumber>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{coordinate[0]}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-inputNumber [(ngModel)]="coordinate[1]"
                                         (onInput)="yCoordinateUpdate(region, ri, $event.value)"
                                         (keydown)="disableArrowKeys($event)">
                          </p-inputNumber>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{coordinate[1]}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td>
                      <div class="flex align-items-center justify-content-center gap-2">
                        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-text
                                p-button-warning" (click)="deleteCoordinate(region, ri)">
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </td>
        </tr>

      </ng-template>
    </p-table>
  </div>
</div>

<div class="p-dialog-footer">
  <button
    pButton
    pRipple
    type="button"
    label="Cancel"
    (click)="cancel()"
  ></button>
  <button
    pButton
    pRipple
    type="button"
    label="Save"
    (click)="save()"
  ></button>
</div>

<p-confirmDialog #cd [style]="{width: '50vw'}" key="positionDialog" position="bottom-right"
                 [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    <button type="button" class="p-button-outlined" pButton icon="pi pi-times" label="No"
            (click)="cd.reject()"></button>
  </ng-template>
</p-confirmDialog>

<p-dialog header="Region editor help" [(visible)]="displayHelpDialog" [style]="{width: '50vw'}"
          [maximizable]="true">
  <p>
    Press the "<object data="assets/icons/shape-rectangle-plus-dark.svg" width="15" height="15"></object>"
    button to add a new rectangle region. Press the "<i class="pi pi-angle-right"></i>" to expand the
    newly created row in the table and then press on the table cells/values to edit each of the rectangle
    values.
  </p>
  <p>
    Press the "<object data="assets/icons/shape-polygon-plus-dark.svg" width="15" height="15"></object>"
    to add a new polyform region. Press the "<i class="pi pi-angle-right"></i>" to expand the row and
    display all the points coordinates that make up the polyform region. A new point can be added to the
    region, or deleted by pressing on the "<i class="pi pi-trash"></i>" icon. The polyform selection is
    displayed as an SVG path in the table row. It is shown as a list of x/y coordinates when expanding
    the row.
  </p>
  <p>
    Any changes done through this dialog will not be persisted unless the "<b>save</b>" button is pressed.
  </p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="displayHelpDialog=false" label="Ok" styleClass="p-button-text">

    </p-button>
  </ng-template>
</p-dialog>
