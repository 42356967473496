import {
  CropRequest,
  EDoFRequest,
  EDoFRequestBuilder,
  QuantRequest,
  SiftRequest,
  SiftRequestBuilder,
  KidneyClassifierRequest,
  KidneyClassifierRequestBuilder,
  DeconvolutionRequest,
  DeconvolutionBuilder,
  YolosegdetectRequest,
  YolosegdetectRequestBuilder, RetinalLayerRequestBuilder, RetinalLayerRequest,
} from './request';
import { IFileInfo } from './file-info';

export class RequestFactory {

  static getEdofRequestFromForm(formValue: any,
                                bucket: string | undefined,
                                parentPath: string | undefined): EDoFRequest {
    const edofBuilder = Object.assign(new EDoFRequestBuilder(), structuredClone(formValue));
    edofBuilder.bucket = bucket;
    edofBuilder.path = parentPath;
    return edofBuilder.build();
  }

  static getQuantRequestFromForm(formValue: any): QuantRequest {
    const newRequest = <QuantRequest>structuredClone(formValue);
    newRequest.fiberSettings.cMax = newRequest.fiberSettings.cMax / 100;
    newRequest.fiberSettings.cMin = newRequest.fiberSettings.cMin / 100;
    newRequest.nucleiSettings.cMax = newRequest.nucleiSettings.cMax / 100;
    newRequest.nucleiSettings.cMin = newRequest.nucleiSettings.cMin / 100;
    return Object.assign(new QuantRequest(), newRequest);
  }

  static getSegmentationRequestFromForm(formValue: any): CropRequest {
    const newRequest = <CropRequest>structuredClone(formValue);
    newRequest.regionSettings.minimumAspectRatio = 0.15;
    newRequest.regionSettings.showRegions = false;
    newRequest.regionSettings.generateProofImage = true;
    newRequest.regionSettings.saveAsJson = true;
    newRequest.tileConfiguration.order = 'IMAGE';
    newRequest.tileConfiguration.clip = true;
    return Object.assign(new CropRequest(), newRequest);
  }

  static getSiftRequestFromForm(formValue: any, bucket: string, parentPath: string): SiftRequest {
    const siftBuilder = Object.assign(new SiftRequestBuilder(), structuredClone(formValue));
    siftBuilder.bucket = bucket;
    siftBuilder.path = parentPath;
    return siftBuilder.build();
  }

  static getKidneyClassifierRequestFromForm(
    formValue: any,
    bucket: string,
    parentPath: string
  ): KidneyClassifierRequest {
    const kidneyBuilder = Object.assign(
      new KidneyClassifierRequestBuilder(),
      structuredClone(formValue)
    );
    kidneyBuilder.bucket = bucket;
    kidneyBuilder.path = parentPath;
    return kidneyBuilder.build();
  }

  static getDeconvolutionRequestFromForm(formValue: any, bucket: string | undefined,
                                         parentPath: string | undefined, inputFile: IFileInfo | undefined):
    DeconvolutionRequest {
    const deconvolutionBuilder = Object.assign(new DeconvolutionBuilder(), structuredClone(formValue));
    deconvolutionBuilder.bucket = bucket;
    deconvolutionBuilder.path = parentPath;
    deconvolutionBuilder.inputImage = inputFile;
    return deconvolutionBuilder.build();
  }

  static  getYolosegdetectRequest(bucket: string | undefined,
                                         parentPath: string | undefined,
                                         inputFiles: IFileInfo[],
                                         modelFile: string,
                                         outputFolder: string): YolosegdetectRequest {
    const yolosegdetectBuilder = new YolosegdetectRequestBuilder();
    yolosegdetectBuilder.bucket = bucket;
    yolosegdetectBuilder.path = parentPath;
    yolosegdetectBuilder.inputImages = inputFiles;
    yolosegdetectBuilder.modelFilePath = modelFile;
    yolosegdetectBuilder.outputFolder = outputFolder;
    return yolosegdetectBuilder.build();
  }

  static  getRetinalLayerRequest(bucket: string | undefined,
                                          parentPath: string | undefined,
                                          inputFiles: IFileInfo[],
                                          modelPath: string,
                                          model: string,
                                          step: number,
                                          outputFolder: string):RetinalLayerRequest {
    const retinalLayerBuilder = new RetinalLayerRequestBuilder();
    retinalLayerBuilder.bucket = bucket;
    retinalLayerBuilder.path = parentPath;
    retinalLayerBuilder.images = inputFiles;
    retinalLayerBuilder.predModel = modelPath;
    retinalLayerBuilder.model = model;
    retinalLayerBuilder.step = step;
    retinalLayerBuilder.outputPath = outputFolder;
    return retinalLayerBuilder.build();
  }
}
