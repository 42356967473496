<div class="w-full col-12 md:col-3 pb-2">
  <p-dropdown
    placeholder="Select Process"
    class="pb-2"
    [options]="processes"
    (onChange)="onProcessSelect($event)"
    [(ngModel)]="selectedTypeObj"
    [disabled]="disableProcess"
  >
    <ng-template pTemplate="selectedItem">
      <div *ngIf="selectedType">
        <div>{{ selectedType }}</div>
      </div>
    </ng-template>

    <ng-template let-process pTemplate="item">
      <div class="card">
        <div class="flex flex-column card-container">
          <div class="flex justify-content-left font-bold text-lg">{{process.name}}</div>
          <div class="flex mt-1 text-base">{{process.desc}}</div>
        </div>
      </div>
    </ng-template>
  </p-dropdown>

  <div class="process flex flex-column">
    <ng-container [ngSwitch]="true">
      <div class="flex flex-column p-2">
        <div *ngSwitchCase="selectedType === PROCESS_TYPES[0].name">
          <segmentation></segmentation>
        </div>
        <div *ngSwitchCase="selectedType === PROCESS_TYPES[1].name">
          <htquant></htquant>
        </div>
        <div *ngSwitchCase="selectedType === PROCESS_TYPES[2].name">
          <sift></sift>
        </div>
        <div *ngSwitchCase="selectedType === PROCESS_TYPES[3].name">
          <edof></edof>
        </div>
        <div *ngSwitchCase="selectedType === PROCESS_TYPES[4].name">
          <kidney-classifier></kidney-classifier>
        </div>
        <div *ngSwitchCase="selectedType === PROCESS_TYPES[5].name">
          <deconvolution></deconvolution>
        </div>
        <div *ngSwitchCase="selectedType === PROCESS_TYPES[6].name">
          <retinal-layer></retinal-layer>
        </div>
        <div *ngSwitchCase="selectedType === PROCESS_TYPES[7].name">
          <yolosegdetect></yolosegdetect>
        </div>
      </div>
    </ng-container>
  </div>
</div>

