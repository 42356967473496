<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <p-divider align="left">
    <div class="inline-flex align-items-center font-bold">
      Inputs
    </div>
  </p-divider>

  <p class="my-2">
    Please select an image file or an <b>expanded</b> folder containing one or more image files in
    the file browser.
  </p>
  <br>
  <div class="field grid">
    <label for="modelFile" class="col-12 mb-2 md:col-3 md:mb-0 text-black-alpha-90">
      Fine-tuned Model
      <i class="pi pi-info-circle px-1"
         pTooltip="Select fine tuned model on the Optic Nerve area or on the Retina area of
         an eyeball section"
         tooltipPosition="top"
      ></i>
    </label>
    <div class="col-12 md:col-9">
      <p-dropdown
        id="modelFile"
        formControlName="modelFile"
        placeholder="SELECT MODEL"
        [options]="models"
        optionLabel="name"
      ></p-dropdown>
    </div>
  </div>

  <div class="field-checkbox">
    <p-checkbox
      formControlName="saveSegment"
      [binary]="true"
      class="my-1"
      id="saveSegment"
    ></p-checkbox>
    <label for="saveSegment">
      Run in segmentation mode
      <i class="pi pi-info-circle px-2"
         pTooltip="If selected, the process will run in segmentation mode.
         If not selected (default), the process will run in detection mode."
         tooltipPosition="top"
      ></i>
    </label>
  </div>
  <div class="field grid">
    <label for="downSamplingFactor" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
      Downsampling Factor
      <i class="pi pi-info-circle px-1"
         pTooltip="Downsampling value used to reduce the image size before the patching process"
         tooltipPosition="top"
      ></i>
    </label>

    <div class="col-12 md:col-5">
      <p-inputNumber
        id="downSamplingFactor"
        formControlName="downSamplingFactor"
        [showButtons]="true"
        [min]="1"
        [max]="10"
        class="p-inputtext-sm"
      ></p-inputNumber>
    </div>
  </div>

  <div class="field grid">
    <label for="overlapX" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
      Overlap X
      <i class="pi pi-info-circle px-1"
         pTooltip="X axis overlap value when creating patches"
         tooltipPosition="top"
      ></i>
    </label>

    <div class="col-12 md:col-5">
      <p-inputNumber
        id="overlapX"
        formControlName="overlapX"
        [showButtons]="true"
        [min]="0"
        [max]="90"
        class="p-inputtext-sm"
      ></p-inputNumber>
    </div>
  </div>
  <div class="field grid">
    <label for="overlapY" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
      Overlap Y
      <i class="pi pi-info-circle px-1"
         pTooltip="Y axis overlap value when creating patches"
         tooltipPosition="top"
      ></i>
    </label>

    <div class="col-12 md:col-5">
      <p-inputNumber
        id="overlapY"
        formControlName="overlapY"
        [showButtons]="true"
        [min]="0"
        [max]="90"
        class="p-inputtext-sm"
      ></p-inputNumber>
    </div>
  </div>

  <div class="field grid">
    <label for="confidence" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
      Confidence
      <i class="pi pi-info-circle px-1"
         pTooltip="Minimum confidence threshold value for prediction results"
         tooltipPosition="top"
      ></i>
    </label>

    <div class="col-12 md:col-5">
      <p-inputNumber
        id="confidence"
        formControlName="confidence"
        [showButtons]="true"
        [min]="0"
        [max]="0.9"
        [step]="0.1"
        [minFractionDigits]="0"
        [maxFractionDigits]="1"
        mode="decimal"
        class="p-inputtext-sm"
      ></p-inputNumber>
    </div>
  </div>
  <div class="field grid">
    <label for="iouThreshold" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
      IOU Threshold
      <i class="pi pi-info-circle px-1"
         pTooltip="IoU threshold for non-maximum suppression YOLOv8 of single crop."
         tooltipPosition="top"
      ></i>
    </label>

    <div class="col-12 md:col-5">
      <p-inputNumber
        id="iouThreshold"
        formControlName="iouThreshold"
        [showButtons]="true"
        [min]="0.1"
        [max]="0.9"
        [step]="0.1"
        [minFractionDigits]="0"
        [maxFractionDigits]="1"
        mode="decimal"
        class="p-inputtext-sm"
      ></p-inputNumber>
    </div>
  </div>
  <div class="field grid">
    <label for="nmsThreshold" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
      NMS Threshold
      <i class="pi pi-info-circle px-1"
         pTooltip="IoU/IoS threshold for non-maximum suppression. The lower the value, the fewer objects
         remain after suppression."
         tooltipPosition="top"
      ></i>
    </label>

    <div class="col-12 md:col-5">
      <p-inputNumber
        id="nmsThreshold"
        formControlName="nmsThreshold"
        [showButtons]="true"
        [min]="0.1"
        [max]="0.9"
        [step]="0.1"
        [minFractionDigits]="0"
        [maxFractionDigits]="1"
        mode="decimal"
        class="p-inputtext-sm"
      ></p-inputNumber>
    </div>
  </div>

  <!-- this section becomes visible when file gets selected -->
  <ng-container>
    <p-divider align="left">
      <div class="inline-flex align-items-center font-bold">
        File Destination
      </div>
    </p-divider>

    <!-- output folder -->
    <div class="field grid h-3rem align-items-center">
      <label for="outputFolder" class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Output folder:
      </label>
      <div class="col-12 md:col-7">
        <ng-container *ngIf="form.get('editOutputFolder')?.value; else viewOutputFolder">
          <input id="outputFolder" type="text" class="p-inputtext-sm w-full"
                 formControlName="outputFolder" pInputText/>
          <i class="pi pi-times icon-hover" (click)="form.get('editOutputFolder')?.setValue(false)"></i>
        </ng-container>
        <ng-template #viewOutputFolder>
          {{form.get('outputFolder')?.value}}
          <i class="pi pi-pencil icon-hover" (click)="form.get('editOutputFolder')?.setValue(true)"></i>
        </ng-template>
      </div>
    </div>
    <div class="pt-3 pb-3">
      <span *ngIf="validationMessage" style="color: red" class="mb-2">{{ validationMessage }}</span>
    </div>
    <div class="pt-3 pb-3 process-buttons">
      <p-button
        type="submit" icon="pi pi-play"
        label="Run"
        styleClass="p-button-primary">
      </p-button>
    </div>

  </ng-container>
</form>
