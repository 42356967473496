import { Injectable } from '@angular/core';

import { PlotlyService } from '../../../services/plotly.service';
import { ImageInfo } from '../../models/file-info';
import { Region } from '../../models/plotting/region';
import { PlotUtilities } from '../../plot.utilities';

@Injectable({
  providedIn: 'root',
})
export class PlotFacade {

  plotUtilities = new PlotUtilities();

  constructor(private plotlyService: PlotlyService) {
  }

  getStackLoadingProgress() {
    return this.plotlyService.getStackLoadingProgress$();
  }

  isStackLoading() {
    return this.plotlyService.isStackLoading$();
  }

  setStackLoading(stackLoading: boolean) {
    this.plotlyService.setStackLoading(stackLoading);
  }
  /**
   * relayout the plot
   */
  relayout() {
    this.plotlyService.relayout();
  }

  /**
   * autoscale the plot
   */
  public autoscale() {
    this.plotlyService.autoscale();
  }

  /**
   * Returns an observable of regions
   */
  public getRegionUpdate() {
    return this.plotlyService.getRegionUpdateEvent();
  }
  /**
   * Reset the plot
   */
  reset() {
    this.plotlyService.reset();
  }

  /**
   * Load image
   * @param imgInfo
   * @param zIndex index of the image to load
   * @return an object with data and ratio keys
   */
  load(imgInfo: ImageInfo, zIndex: number){
    return this.plotlyService.load(imgInfo, zIndex);
  }

  /**
   * Plot loadedImage given its ImageInfo and a screeheight
   * @param plotDivName
   * @param loadedImage object with data, ratios and sizes key
   * @param imgInfo ImageInfo object
   * @param screenHeight size of the available screen height
   */
  plot(plotDivName: string, loadedImage: {
    data: any;
    sizes: number[];
    ratios: number[]
  }, imgInfo: ImageInfo, screenHeight: number) {
    return this.plotlyService.plot(plotDivName, loadedImage, imgInfo, screenHeight);
  }

  reloadAndPlot() {
    this.plotlyService.reloadAndPlot();
  }
  setRegions(regions: Region[], showRegionLabel?: boolean, isRegionSaveOn?: boolean,
              shapeColor?: string, fillColor?: string, labelColor?: string) {
    this.plotlyService.setRegions(regions, showRegionLabel, isRegionSaveOn, shapeColor, fillColor, labelColor);
  }

  /**
   * Get all the selected regions as polygons
   */
  public getRegionPolygons() {
    return this.plotlyService.getRegionPolygons();
  }

  setPreviousShapes() {
    this.plotlyService.setPreviousShapes();
  }

  getShapes() {
    return this.plotlyService.getShapes();
  }

  /**
   * coordinates are of the bottom left and upper right corners of the rectangle, The coordinates
   * are taken given a yAxis that is up side down (as for all images)
   * @param coordinates plotly coordinates ([Xaxis.range[0], xAxis.range[1], yAxis.range[0], yaxis.range[1]])
   * @param trueImageSize true image size [0, x, 0, y]
   */
  public getRectangle(coordinates: number[], trueImageSize: number[]) {
    this.plotUtilities.getRectangle(coordinates, trueImageSize);
  }

  getColormapOptions() {
    return this.plotlyService.getColormapOptions();
  }
  setColormap(colormap: string) {
    this.plotlyService.setColormap(colormap);
  }

  getColormap() {
    return this.plotlyService.getColormap();
  }
  setReverseScale(reverscale: boolean) {
    this.plotlyService.setReverscale(reverscale);
  }

  setScaleRatio(scaleratio: boolean){
    this.plotlyService.setScaleRatio(scaleratio);
  }
  getReverseScale() {
    return this.plotlyService.getReverseScale();
  }

  getScaleRatio() {
    return this.plotlyService.getScaleRatio();
  }

  getShowShapeLabel(): boolean {
    return this.plotlyService.getShowShapeLabel();
  }
  setShowStack(showstack: boolean) {
    this.plotlyService.setShowStack(showstack);
  }

  getAutoscaleEvent() {
    return this.plotlyService.getAutoscaleEvent();
  }

  setZIndex(zIndex: number) {
    this.plotlyService.setZIndex(zIndex);
  }

  getShapeColor() {
    return this.plotlyService.getShapeColor();
  }
  getFillColor() {
    return this.plotlyService.getFillColor();
  }
  getLabelColor() {
    return this.plotlyService.getLabelColor();
  }

  unsubscribe() {
    this.plotlyService.unsubscribe();
  }

  importRegions(geoJsonStr: string): Region[] {
    return this.plotUtilities.importROIsFromGeoJson(geoJsonStr);
  }

  exportRegions(regions: Region[]) {
    const jsonString = this.plotUtilities.exportROIsToGeoJson(regions);
    this.plotUtilities.saveToFile(jsonString);
  }
}
