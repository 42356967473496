import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { MainFacade } from '../../../main.facade';
import { ProcessFacade } from '../process.facade';
import { IFileInfo } from '../../../models/file-info';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestFactory } from '../../../models/request.factory';

@Component({
  selector: 'retinal-layer',
  templateUrl: './retinal-layer.component.html',
  styleUrls: ['./retinal-layer.component.scss'],
})
export class RetinalLayerComponent implements OnInit {

  form!: FormGroup;
  public bucket: string | undefined = undefined;
  public path: string | undefined = undefined;
  validationMessage: string | null = null;

  public inputImages: IFileInfo[] = [];

  public models: any[] = [ 'resunet_a_2d', 'vnet_2d' ];
  selectedModel = 'resunet_a_2d';
  public resunetModels: any[] = [
    { name: 'resunet-a-2d-RL-python3-10-keras-2-15-keras-unet-collection-0-1-13', value: 'jax-ml-dl-models/Retinal-layer-ML-models/resunet-a-2d-RL-python3-10-keras-2-15-keras-unet-collection-0-1-13.keras' }
  ];
  public vnetModels: any[] = [
    { name: 'vnet-2d-RL-python3-10-keras-2-15-keras-unet-collection-0-1-13', value: 'jax-ml-dl-models/Retinal-layer-ML-models/vnet-2d-RL-python3-10-keras-2-15-keras-unet-collection-0-1-13.keras' }
  ]
  steps = [ 512, 256, 128 ];

  constructor(private mainFacade: MainFacade,
              private processFacade: ProcessFacade,
              private fb: FormBuilder) {}

  ngOnInit(): void {
    this.configForm();
    this.mainFacade.getSelectedNode$().subscribe({
      next: (node) => {
        if (node == undefined || node.data == undefined) {
          this.bucket = undefined;
        } else {
          this.setImagesInput(node);
        }
      },
      error: (err) => {
        console.error(`Error with node selection: ${err}`);
      },
    });
    this.mainFacade.getValidationMessage$().subscribe({
      next: (message) => {
        this.validationMessage = message;
      }
    });
  }

  setImagesInput(node: TreeNode<IFileInfo>) {
    this.inputImages = [];
    // if folder
    if (!node.leaf) {
      node.children?.forEach(child => {
        if (child.data && this.isImageExtension(child.data.name)) {
          this.inputImages.push(child.data);
        }
      });
    } else {
      // if file
      if (node.data && this.isImageExtension(node.data.name)) {
        this.inputImages.push(node.data);
      }
    }
    this.bucket = node.data?.project.bucket;
    this.path = node.parent?.data?.relPath;
  }

  isImageExtension(name: string) {
    const extension = name.toLowerCase().split('.').pop();
    return extension === 'tif' || extension === 'tiff' || extension === 'png' || extension === 'jpg' || extension === 'jpeg' || extension === 'ndpi';
  }
  configForm() {
    this.form = this.fb.group({
      model: [this.models[0], Validators.required],
      resunetModel: [this.resunetModels[0], Validators.required],
      vnetModel: [this.vnetModels[0], Validators.required],
      step: [512, Validators.required],
      outputFolder: [null, Validators.required],
      editOutputFolder: [false]
    });
  }

  selectModel(model: string) {
    this.selectedModel = model;
  }

  onSubmit() {
    const formValue = this.form.value;
    const bucket = this.bucket;
    const parentPath = this.path;
    const modelPath = formValue.model === this.models[0]
      ? formValue.resunetModel.value : formValue.vnetModel.value;
    const yolosegdetectRequest = RequestFactory.getRetinalLayerRequest(
      bucket, parentPath, this.inputImages, modelPath, formValue.model, formValue.step,
      formValue.outputFolder);
    this.mainFacade.runProcess(yolosegdetectRequest);
  }

}
