<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <p-divider align="left">
    <div class="inline-flex align-items-center font-bold">
      Inputs
    </div>
  </p-divider>

  <p class="my-2">
    Please select an image file or an <b>expanded</b> folder containing one or more image files in
    the file browser. The image size (height and width) should be divisible by 512.
  </p>
  <br>
  <div class="field grid">
    <label for="model" class="col-12 mb-2 md:col-3 md:mb-0 text-black-alpha-90">
      Model type
      <i class="pi pi-info-circle px-1"
         pTooltip="Select the type of model to run the prediction"
         tooltipPosition="top"
      ></i>
    </label>
    <div class="col-12 md:col-9">
      <p-dropdown
        id="model"
        formControlName="model"
        placeholder="SELECT MODEL"
        (onChange)="selectModel($event.value)"
        [options]="models"
      ></p-dropdown>
    </div>
  </div>

  <div *ngIf="selectedModel === models[0]" class="field grid">
    <label for="resunetmodel" class="col-12 mb-2 md:col-3 md:mb-0 text-black-alpha-90">
      Model name
      <i class="pi pi-info-circle px-1"
         pTooltip="Select the resunet fine-tuned model"
         tooltipPosition="top"
      ></i>
    </label>
    <div class="col-12 md:col-9">
      <p-dropdown
        id="resunetmodel"
        formControlName="resunetModel"
        placeholder="Select Resunet model"
        [options]="resunetModels"
        optionLabel="name"
      ></p-dropdown>
    </div>
  </div>

  <div *ngIf="selectedModel === models[1]" class="field grid">
    <label for="vnetmodel" class="col-12 mb-2 md:col-3 md:mb-0 text-black-alpha-90">
      Model name
      <i class="pi pi-info-circle px-1"
         pTooltip="Select the vnet fine-tuned model"
         tooltipPosition="top"
      ></i>
    </label>
    <div class="col-12 md:col-9">
      <p-dropdown
        id="vnetmodel"
        formControlName="vnetModel"
        placeholder="Select Resunet model"
        [options]="vnetModels"
        optionLabel="name"
      ></p-dropdown>
    </div>
  </div>

  <div class="field grid">
    <label for="step" class="col-12 mb-2 md:col-3 md:mb-0 text-black-alpha-90">
      Step size
      <i class="pi pi-info-circle px-1"
         pTooltip="Step size used for the overlap between each patches: 512 = no overlap,
         256 = 256 pixels overlap, 128 = 384 pixels overlap"
         tooltipPosition="top"
      ></i>
    </label>
    <div class="col-12 md:col-9">
      <p-dropdown
        id="step"
        formControlName="step"
        placeholder="Select step size"
        [options]="steps"
      ></p-dropdown>
    </div>
  </div>

  <!-- this section becomes visible when file gets selected -->
  <ng-container>
    <p-divider align="left">
      <div class="inline-flex align-items-center font-bold">
        File Destination
      </div>
    </p-divider>

    <!-- output folder -->
    <div class="field grid h-3rem align-items-center">
      <label for="outputFolder" class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Output folder:
      </label>
      <div class="col-12 md:col-7">
        <ng-container *ngIf="form.get('editOutputFolder')?.value; else viewOutputFolder">
          <input id="outputFolder" type="text" class="p-inputtext-sm w-full"
                 formControlName="outputFolder" pInputText/>
          <i class="pi pi-times icon-hover" (click)="form.get('editOutputFolder')?.setValue(false)"></i>
        </ng-container>
        <ng-template #viewOutputFolder>
          {{form.get('outputFolder')?.value}}
          <i class="pi pi-pencil icon-hover" (click)="form.get('editOutputFolder')?.setValue(true)"></i>
        </ng-template>
      </div>
    </div>
    <div class="pt-3 pb-3">
      <span *ngIf="validationMessage" style="color: red" class="mb-2">{{ validationMessage }}</span>
    </div>
    <div class="pt-3 pb-3 process-buttons">
      <p-button
        type="submit" icon="pi pi-play"
        label="Run"
        styleClass="p-button-primary">
      </p-button>
    </div>

  </ng-container>
</form>
