import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { MainFacade } from '../../../main.facade';
import { ProcessFacade } from '../process.facade';
import { IFileInfo } from '../../../models/file-info';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestFactory } from '../../../models/request.factory';

@Component({
  selector: 'deconvolution',
  templateUrl: './deconvolution.component.html',
  styleUrls: ['./deconvolution.component.scss'],
})
export class DeconvolutionComponent implements OnInit {

  form!: FormGroup;
  public bucket: string | undefined = undefined;
  public path: string | undefined = undefined;
  validationMessage: string | null = null;
  public inputImage: IFileInfo | undefined;
  stainAbsorptionReferences = [
    { name: 'hematoxylin', value: [0.563, 0.720, 0.406] },
    { name: 'eosin', value: [0.216, 0.801, 0.558] },
    { name: 'DAB', value: [0.268, 0.57, 0.776] },
    { name: 'fast red', value: [0.214, 0.851, 0.478] },
    { name: 'fast blue', value: [0.749, 0.606, 0.267] },
    { name: 'methyl blue', value: [0.799, 0.591, 0.105] },
    { name: 'methyl green', value: [0.98, 0.144, 0.133] },
    { name: 'AEC', value: [0.274, 0.679, 0.68] },
    { name: 'aniline blue', value: [0.853, 0.509, 0.113] },
    { name: 'azocarmine', value: [0.071, 0.977, 0.198] },
    { name: 'alcian blue', value: [0.875, 0.458, 0.158] },
    { name: 'PAS', value: [0.175, 0.972, 0.155] },
    { name: 'hematoxylin and PAS', value: [0.553, 0.754, 0.354] },
    { name: 'Feulgen', value: [0.464, 0.83, 0.308] },
    { name: 'methylene blue', value: [0.553, 0.754, 0.354] },
    { name: 'orange-G', value: [0.107, 0.368, 0.923] },
    { name: 'ponceau-fuchsin', value: [0.1, 0.737, 0.668] },
  ];

  public children: IFileInfo[] = [];

  isOutputPathEditable = false;

  constructor(private mainFacade: MainFacade,
              private processFacade: ProcessFacade,
              private fb: FormBuilder) {}

  ngOnInit(): void {
    this.configForm();
    this.mainFacade.getSelectedNode$().subscribe({
      next: (node) => {
        if (node == undefined || node.data == undefined) {
          this.bucket = undefined;
        } else {
          this.setImageInput(node);
        }
      },
      error: (err) => {
        console.error(`Error with node selection: ${err}`);
      },
    });
    this.mainFacade.getValidationMessage$().subscribe({
      next: (message) => {
        this.validationMessage = message;
      }
    });
  }

  setImageInput(node: TreeNode<IFileInfo>) {
    // if file
    if (node.leaf) {
      if (node.data && this.isImageExtension(node.data.name)) {
        this.inputImage = node.data;
      }
    }
    this.bucket = node.data?.project.bucket;
    this.path = node.parent?.data?.relPath;
  }

  isImageExtension(name: string) {
    const extension = name.toLowerCase().split('.').pop();
    return extension === 'tif' || extension === 'tiff' || extension === 'png' || extension === 'jpg' || extension === 'jpeg' || extension === 'ndpi';
  }

  configForm() {
    this.form = this.fb.group({
      stain1: [this.stainAbsorptionReferences[0], Validators.required],
      red1: [0.563, Validators.required],
      green1: [0.720, Validators.required],
      blue1: [0.406, Validators.required],
      stain2: [this.stainAbsorptionReferences[1], Validators.required],
      red2: [0.216, Validators.required],
      green2: [0.801, Validators.required],
      blue2: [0.558, Validators.required],
      stain1max: [2.0, Validators.required],
      stain2max: [1.0, Validators.required],
      alpha: [1, Validators.required],
      beta: [0.15, Validators.required],
      intensityNorm: [240, Validators.required],
      grayscale: [false, Validators.required],
      outputPath: ['', Validators.required]

    });
  }

  onSubmit() {
    const bucket = this.bucket;
    const parentPath = this.path;
    const deconvolutionRequest = RequestFactory.getDeconvolutionRequestFromForm(
      this.form.value, bucket, parentPath, this.inputImage);
    this.mainFacade.runProcess(deconvolutionRequest);
  }

  /* sets translated output input field's editability */
  onEditOutputPath(val: boolean) {
    this.isOutputPathEditable = val;
  }

  onStain1Select(absorption: { name: string, value: number[] }) {
    // update stain 1 values
    this.form.get('red1')?.setValue(absorption.value[0]);
    this.form.get('green1')?.setValue(absorption.value[1]);
    this.form.get('blue1')?.setValue(absorption.value[2]);
  }

  onStain2Select(absorption: { name: string, value: number[] }) {
    // update stain 2 values
    this.form.get('red2')?.setValue(absorption.value[0]);
    this.form.get('green2')?.setValue(absorption.value[1]);
    this.form.get('blue2')?.setValue(absorption.value[2]);
  }
}


